const Information = ({ mainPageData, footer }) => {
  if (mainPageData && !footer) {
    const sortedData = mainPageData
      .filter((data) => data.position === "main")
      .sort((a, b) => a.order - b.order);
    return sortedData.map((block, index) => (
      <article className="information" key={index}>
        <h2 className="information__header">{block["headline"]}</h2>
        <div dangerouslySetInnerHTML={{ __html: block["description"] }} />
      </article>
    ));
  }
  if (footer && mainPageData) {
    const sortedFooterData = mainPageData
      .filter((data) => data.position === "footer")
      .sort((a, b) => a.order - b.order);
    return sortedFooterData.map((block, index) => (
      <article className="information" key={index}>
        <h2 className="information__header">{block["headline"]}</h2>
        <div dangerouslySetInnerHTML={{ __html: block["description"] }} />
      </article>
    ));
  }
};

export default Information;
