import React, { useEffect, useState } from "react";
import {
  adjustHeaderParagraphSize,
  determineCharacterLength,
} from "../js/adjustHeaderParagraphSize.js";
import htmlToJson from "../js/convertHTMLtoJSON.js";
import DonationButton from "./donationButton.jsx";

const Posts = ({
  mainPageArticles,
  sectionHeadline,
  articlesToShow,
  postsData,
  PUBLIC_CMS_IP,
}) => {
  const [browserWidth, setBrowserWidth] = useState();
  const [headlineLength, setHeadlineLength] = useState();
  const [paragraphLength, setParagraphLength] = useState();

  const handleResize = () => {
    setBrowserWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const { newHeadlineLength, newParagraphLength } =
      determineCharacterLength(browserWidth);
    setHeadlineLength(newHeadlineLength);
    setParagraphLength(newParagraphLength);
  }, [browserWidth]);

  function createSourceLink(inputString) {
    // Check if inputString is null, undefined, or empty
    if (!inputString) return null;

    // Split the string by the colon to extract the name and URL

    // Check if both name and url exist after the split

    // Split the string by the colon to extract the name and URL
    const [name, url] = inputString.split(">");

    if (!name || !url) return null;

    return (
      <span className="photo_details">
        <a
          className="photo_link"
          href={url + "?utm_source=sacuvaj&utm_medium=referral"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </a>
      </span>
    );
  }

  if (mainPageArticles) {
    return (
      <article className="information">
        <h2 className="information__header">{sectionHeadline}</h2>
        {mainPageArticles.map((article, index) => {
          if (index <= articlesToShow - 1) {
            const slug = article.slug;
            const uniqueID = article.unique_id;
            const articleURL = uniqueID + "/" + slug;
            // const imgItem = article.data.find((item) => item.tag === "img");
            let imgItem = "";
            let imgDetails = null;

            const imgItemIndex = article.data.findIndex(
              (item) => item.tag === "img"
            );

            if (imgItemIndex !== -1) {
              imgItem = article.data[imgItemIndex];

              // Check if the next element is an "img_details" and assign it
              if (
                article.data[imgItemIndex + 1] &&
                article.data[imgItemIndex + 1].tag === "img_details"
              ) {
                imgDetails = article.data[imgItemIndex + 1].text;
              }
            }
            let thumbnail;

            if (imgItem && imgItem.text) {
              thumbnail = imgItem.text;
            } else {
              thumbnail = "https://picsum.photos/1900/800";
            }
            let headline = article.data.find((item) => item.tag === "h1").text;
            let paragraph = article.data.find((item) => item.tag === "p").text;

            ({ headline, paragraph } = adjustHeaderParagraphSize(
              headline,
              headlineLength,
              paragraph,
              paragraphLength
            ));

            return (
              <React.Fragment key={index}>
                <article className="information__post">
                  <div className="information__post__holder">
                    <a className="information__post__link" href={articleURL}>
                      <div
                        className="information__thumbnailHolder"
                        style={{
                          backgroundImage: `url(${thumbnail})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    </a>
                    {createSourceLink(imgDetails)}
                  </div>
                  <section className="postInfo">
                    <a href={articleURL}>
                      <h3 className="postInfo__header">{headline}</h3>
                    </a>
                    <p className="postInfo__paragraph">{paragraph}</p>
                  </section>
                </article>
              </React.Fragment>
            );
          }
        })}
        <p className="content__credit_unsplash">
          Slike iz ove sekcije su preuzete sa servisa{" "}
          <a
            href="https://unsplash.com/?utm_source=sacuvaj&utm_medium=referral"
            className="photo_link--main"
            target="_blank"
            rel="noopener noreferrer"
          >
            Unsplash
          </a>
          .
        </p>
      </article>
    );
  }
  if (postsData) {
    return (
      <article className="information">
        <h2 className="information__header">{sectionHeadline}</h2>
        {postsData.map((post, index) => {
          if (index + 1 <= articlesToShow) {
            const isFeaturedPost = post["featured"] === "yes";
            const convertedContent = htmlToJson(post["content"]);
            const findFirstParagraph = convertedContent.find(
              (item) => item["tag"] === "p"
            );
            let paragraph, headline;
            const pageHeadline = post["title"];
            const firstParagraph = findFirstParagraph["content"];
            const cmsSource = `${PUBLIC_CMS_IP}assets/`;
            const imagePath = post["featured_image"];
            const featuredImageURL = cmsSource + imagePath;
            const slug = post["slug"];
            // const postLink = websiteSource + slug;

            ({ paragraph, headline } = adjustHeaderParagraphSize(
              pageHeadline,
              headlineLength,
              firstParagraph,
              paragraphLength
            ));

            return (
              <article
                key={index}
                className={
                  isFeaturedPost
                    ? "information__post information__post--featured"
                    : "information__post"
                }
              >
                <div className="information__post__holder">
                  <a className="information__post__link" href={slug}>
                    <div
                      className="information__thumbnailHolder"
                      style={{
                        backgroundImage: `url(${featuredImageURL})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </a>
                </div>
                <section className="postInfo">
                  <a href={slug}>
                    <h3 className="postInfo__header">{headline}</h3>
                  </a>
                  <p className="postInfo__paragraph">{paragraph}</p>
                </section>
              </article>
            );
          }
        })}
        <DonationButton posts={true} />
      </article>
    );
  }
};

export default Posts;
