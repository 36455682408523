import Information from "./information.jsx";
import Posts from "./posts.jsx";

const ContentRow = ({
  mainPageArticles,
  postsData,
  mainPageData,
  main,
  homepagePosts,
  PUBLIC_CMS_IP,
}) => {
  const renderRow = () => {
    if (main) {
      return (
        <>
          <Information mainPageData={mainPageData} />
        </>
      );
    }
    if (mainPageArticles) {
      return (
        <>
          <Posts
            sectionHeadline="Najnovije sačuvano"
            mainPageArticles={mainPageArticles}
            articlesToShow={homepagePosts}
          />
          <Posts
            sectionHeadline="Najnovije objave"
            postsData={postsData}
            articlesToShow={homepagePosts}
            PUBLIC_CMS_IP={PUBLIC_CMS_IP}
          />
        </>
      );
    }
  };

  return <section className="content__featureOverview">{renderRow()}</section>;
};

export default ContentRow;
